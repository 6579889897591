import Swiper from "swiper";
import "swiper/css";

(() => {
  const BWDArticleSwiper = () => {
    const selectors = {
      component: "bwd-article-swiper",
    };

    const init = () => {
      const parentElement = Array.from(document.getElementsByClassName(selectors.component));
      if (parentElement) {
        parentElement.forEach((element) => {
          const swiperElement = element?.querySelector(".swiper");
          if (swiperElement) {
            new Swiper(element.querySelector(".swiper"), {
              slidesPerView: 1,
              freeMode: false,
              loop: true,
              navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              },
            });
          }
        });
      }
    };
    init();
  };
  document.addEventListener("DOMContentLoaded", BWDArticleSwiper);
})();
