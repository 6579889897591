import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
Swiper.use([Navigation, Pagination]);
(() => {
  const BWDComparatorCard = () => {
    const selectors = {
      component: ".bwd-comparator-card",
      swiper: ".js-init-swiper",
      modal: ".js-swiper-modal",
      modalSwiper: ".js-init-swiper-modal",
      modalButton: ".js-open-carousel-modal",
      closeModal: ".js-close-carousel-modal",
    };

    const classes = {
      modalOpen: "mod--open",
      disableScroll: "mod--disable-scroll",
    };

    const modalHandler = (modal, openButton, closeButton) => {
      const swiperElement = modal.querySelector(selectors.modalSwiper);

      if (swiperElement) {
        new Swiper(swiperElement, {
          slidesPerView: 1,
          freeMode: false,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
          loop: false,
          navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          },
        });
      }

      openButton.addEventListener("click", () => {
        modal.classList.add(classes.modalOpen);
        document.documentElement.classList.add(classes.disableScroll);
      });

      closeButton.addEventListener("click", () => {
        modal.classList.remove(classes.modalOpen);
        document.documentElement.classList.remove(classes.disableScroll);
      });
    };

    const init = () => {
      const parentElement = Array.from(document.querySelectorAll(selectors.component));
      if (parentElement) {
        parentElement.forEach((element) => {
          const swiperElement = element.querySelector(selectors.swiper);
          if (swiperElement) {
            new Swiper(swiperElement, {
              slidesPerView: 1,
              freeMode: false,
              pagination: {
                el: ".swiper-pagination",
                clickable: true,
              },
              loop: false,
            });
          }

          const modal = element.querySelector(selectors.modal);
          const openCarouselModal = element.querySelector(selectors.modalButton);
          const closeCarouselModal = element.querySelector(selectors.closeModal);
          if (modal && openCarouselModal && closeCarouselModal) {
            modalHandler(modal, openCarouselModal, closeCarouselModal);
          }
        });
      }
    };
    init();
  };
  document.addEventListener("DOMContentLoaded", BWDComparatorCard);
})();
