(() => {
  const showAnimation = () => {
    const selectors = {
      elementToAnimate: ".mod--animation-show",
    };

    const observerHandler = (element, className) => {
      const observer = new IntersectionObserver(
        function (entries) {
          if (entries[0].isIntersecting === true) {
            entries[0].target.classList.add(className);
            this.disconnect();
          }
        },
        {
          threshold: [0],
        },
      );
      observer.observe(element);
    };

    const init = () => {
      const elementsToAnimate = Array.from(document.querySelectorAll(selectors.elementToAnimate));
      elementsToAnimate.forEach(function (element) {
        observerHandler(element, "mod--animation-show--active");
      });
    };
    init();
  };

  document.addEventListener("DOMContentLoaded", showAnimation);
})();
