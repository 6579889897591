const noHoverMediaQuery = window.matchMedia("(hover: none)");

let mediaQueryMatches = noHoverMediaQuery.matches;
noHoverMediaQuery.addEventListener("change", (evt) => (mediaQueryMatches = evt.matches));

/**
 * Returns whether the client is a touch device.
 *
 * It actually returns true if the client's **primary** input device is not able to hover. This usually means that the
 * primary input in the device it is running is touch (mobile and tablets, with or without mouse connected, but not
 * touch-enabled laptops if their mouse/touchpad is active).
 *
 * This is regarded as a more reliable way to obtain the preferred pointing method of the platform than other methods,
 * like inspecting the user-agent.
 * @returns {boolean} True if the client is a touch device. False otherwise.
 */
export const isTouchDevice = () => mediaQueryMatches;

/**
 * Creates an event listener for changes in the primary touch device. See "isTouchDevice" for more info.
 * @param {(isTouchDevice: boolean) => void} callback Callback to trigger on touch device event.
 */
export const addChangeTouchDeviceListener = (callback) => noHoverMediaQuery.addEventListener("change", (evt) => callback(evt.matches));
