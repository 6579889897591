import Swiper from "swiper";
import { Navigation } from "swiper/modules";

const cmpName = "bwd-packages-banner";

Array.from(document.getElementsByClassName(cmpName)).forEach((cmpElem) => {
  const elemSwiper = cmpElem.getElementsByClassName(`${cmpName}__swiper`)[0];
  if (elemSwiper.getElementsByClassName("swiper-slide").length === 0) {
    elemSwiper.parentElement.remove();
    return;
  }

  try {
    new Swiper(elemSwiper, {
      centerInsufficientSlides: true,
      modules: [Navigation],
      navigation: {
        prevEl: cmpElem.getElementsByClassName("prev")[0],
        nextEl: cmpElem.getElementsByClassName("next")[0],
      },
      slidesPerView: "auto",
      spaceBetween: 24,
    });
  } catch (err) {
    console.error(`Error initializing Swiper in ${cmpName}`, err);
    return;
  }
});
