import * as bwdContactForm from "../bwd-contact-form/bwd-contact-form";

(() => {
  const BWDTeaser = () => {
    const init = () => {
      Array.from(document.getElementsByClassName("bwd-teaser")).forEach((cmp) => {
        const teaser = cmp.getElementsByClassName("c-bwd-teaser")[0];
        const cta = teaser.getElementsByClassName("cta")[0];
        if (!cta) {
          return;
        }

        let isCtaAttachedToModal = false;
        bwdContactForm.addEventListenerOnInserted((contactFormElem) => {
          const modal = contactFormElem.getElementsByClassName("js-contact-modal")[0];
          if (isCtaAttachedToModal || !modal || !cta.dataset.openModal) {
            return;
          }
          isCtaAttachedToModal = true;

          cta.getElementsByTagName("a")[0]?.addEventListener("click", (evt) => {
            evt.preventDefault();
            modal.classList.add("mod--open");
            document.documentElement.classList.add("mod--disable-scroll");
          });
        });
      });
    };

    init();
  };
  document.addEventListener("DOMContentLoaded", BWDTeaser);
})();
