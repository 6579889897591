/* global google */

const iconPointMetadata = {
  anchor: [24, 24],
  labelGap: 16,
  size: [48, 48],
  url: "/content/dam/barcelo/commons/icons/ui/poi-shadow.svg",
};

const iconSelectedMetadata = {
  anchor: [28, 48],
  labelGap: 16,
  size: [56, 64],
  url: "/content/dam/barcelo/commons/icons/ui/poi-shadow-selected.svg",
};

const buildIcon = (meta) => {
  return {
    anchor: new google.maps.Point(meta.anchor[0], meta.anchor[1]),
    labelOrigin: new google.maps.Point(meta.anchor[0], meta.anchor[1] + meta.labelGap),
    scaledSize: new google.maps.Size(meta.size[0], meta.size[1]),
    url: meta.url,
  };
};

/**
 * Returns GMaps-API icon data for the icon "Point". Requires GMaps API to be loaded previously.
 * @returns {any} Icon "Point" data.
 */
export const iconPoint = () => buildIcon(iconPointMetadata);

/**
 * Returns GMaps-API icon data for the icon "Selected". Requires GMaps API to be loaded previously.
 * @returns {any} Icon "Selected" data.
 */
export const iconSelected = () => buildIcon(iconSelectedMetadata);
