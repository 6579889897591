const initCopyBtn = (btn, code) => {
  const textAfter = btn.dataset.textAfter?.trim() || "Copied!";
  const textBefore = btn.dataset.textBefore?.trim() || "Copy";

  btn.addEventListener("click", () => {
    navigator.clipboard.writeText(code).catch((err) => console.error("Error copying to clipboard:", err));
    btn.disabled = true;
    btn.innerText = textAfter;
    setTimeout(() => {
      btn.disabled = false;
      btn.innerText = textBefore;
    }, 2000);
  });
};

const initShareBtn = (btn, code) => {
  btn.addEventListener("click", () => {
    navigator.share({ text: code }).catch((err) => {
      if (err?.name !== "AbortError") {
        console.error("Error sharing code:", err);
      }
    });
  });
};

Array.from(document.getElementsByClassName("bwd-discount-banner")).forEach((banner) => {
  const code = banner.getElementsByClassName("bwd-discount-banner__code")[0]?.textContent?.replace(/\s+/g, " ")?.trim();
  if (!code) {
    return;
  }

  Array.from(banner.getElementsByTagName("button")).forEach((btn) => {
    if (btn.classList.contains("copy")) {
      initCopyBtn(btn, code);
    }
    if (btn.classList.contains("share")) {
      initShareBtn(btn, code);
    }
  });
});
