(() => {
  const BWDArticlesList = () => {
    const selectors = {
      articlesList: ".bwd-articles-list",
      tabs: ".bwd-articles-list__tabs",
      tab: ".bwd-articles-list__tab",
      activeTab: ".bwd-articles-list__tab--active",
      activeCard: ".bwd-articles-list__article-wrapper.mod--active",
      seeMoreButton: ".bwd-articles-list__see-more",
      hiddenCards: ".js-bwd-articles-card-wrapper.hidden",
    };

    const handleTab = (component, tab) => {
      const tag = tab.innerText.trim();
      const filteredCategory = component.querySelector(`[data-article-tag="${tag}"]`);
      const activeTab = component.querySelector(selectors.activeTab);
      const seeMoreButton = component.querySelector(selectors.seeMoreButton);
      let activeCard = component.querySelector(selectors.activeCard);

      activeTab.classList.remove("bwd-articles-list__tab--active");
      tab.classList.add("bwd-articles-list__tab--active");

      activeCard.classList.remove("mod--active");
      filteredCategory.classList.add("mod--active");

      activeCard = filteredCategory;

      const hiddenCards = activeCard.querySelectorAll(selectors.hiddenCards);
      const cardsToShow = [...hiddenCards].slice(0, 6);

      if (cardsToShow.length > 0) {
        seeMoreButton.classList.remove("hidden");
      } else {
        seeMoreButton.classList.add("hidden");
      }
    };

    const handleTabs = (component) => {
      const allTabs = component.querySelectorAll(selectors.tab);

      handleTab(component, allTabs[0]);

      allTabs.forEach((tab) => {
        tab.addEventListener("click", () => {
          handleTab(component, tab);
        });
      });
    };

    const handleSeeMore = (component) => {
      const seeMoreButton = component.querySelector(selectors.seeMoreButton);

      seeMoreButton.addEventListener("click", () => {
        const activeCard = component.querySelector(selectors.activeCard);
        const hiddenCards = activeCard.querySelectorAll(selectors.hiddenCards);
        const cardsToShow = [...hiddenCards].slice(0, 6);

        if (hiddenCards[cardsToShow.length]) {
          seeMoreButton.classList.remove("hidden");
        } else {
          seeMoreButton.classList.add("hidden");
        }

        cardsToShow.forEach((card) => {
          card.classList.remove("hidden");
        });
      });
    };

    const init = () => {
      const articlesLists = Array.from(document.querySelectorAll(selectors.articlesList));

      articlesLists.forEach((component) => {
        handleTabs(component);
        handleSeeMore(component);
      });
    };

    init();
  };
  document.addEventListener("DOMContentLoaded", BWDArticlesList);
})();
