import Swiper from "swiper";
import { Navigation } from "swiper/modules";

(() => {
  const BWDTabs = () => {
    const forceArrowVisibility = (swiper, arrows, mask) => {
      arrows[0].classList.toggle("swiper-button-disabled", swiper.isBeginning);
      arrows[1].classList.toggle("swiper-button-disabled", swiper.isEnd);
      mask.classList.toggle("left", !swiper.isBeginning);
      mask.classList.toggle("right", !swiper.isEnd);
    };

    const changeGradient = (swiper, arrows, mask) => {
      const mutObs = new MutationObserver((mutationList) => {
        mutationList
          .filter((mutation) => mutation.attributeName === "class")
          .forEach(() => {
            forceArrowVisibility(swiper, arrows, mask);
          });
      });
      arrows.forEach((arrow) => mutObs.observe(arrow, { attributes: true }));
    };

    const fixScroll = (swiper, arrows) => {
      const screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
      const params = screenWidth < 1024 ? { itemWidth: 172, spaceBetween: 8 } : { itemWidth: 212, spaceBetween: 24 };
      const numChildren = swiper.wrapperEl.childElementCount;
      const maxTranslate = params.itemWidth * numChildren + params.spaceBetween * (numChildren - 1) - swiper.size;
      if (maxTranslate <= 0) {
        swiper.snapGrid = [0];
        arrows.forEach((arrow) => arrow.classList.add("swiper-button-disabled"));
        return;
      }

      const snapGrid = [];
      for (let i = 0; i < maxTranslate; i += params.itemWidth + params.spaceBetween) {
        snapGrid.push(i);
      }
      snapGrid.push(maxTranslate);
      swiper.snapGrid = snapGrid;
    };

    const init = () => {
      Array.from(document.querySelectorAll(".bwd-articles-list, .bwd-tabs")).forEach((cmpTabs) => {
        const leftArrow = cmpTabs.getElementsByClassName("swiper-button-prev")[0];
        const rightArrow = cmpTabs.getElementsByClassName("swiper-button-next")[0];
        const arrows = [leftArrow, rightArrow];

        new Swiper(cmpTabs.getElementsByClassName("swiper")[0], {
          modules: [Navigation],
          centerInsufficientSlides: true,
          observer: true,
          observeParents: true,
          slidesPerView: "auto",
          spaceBetween: 0,
          navigation: {
            prevEl: leftArrow,
            nextEl: rightArrow,
          },
          on: {
            afterInit: (swiper) => {
              // Wrap swiper-wrapper for masking
              const wrapperMask = document.createElement("div");
              wrapperMask.classList.add("swiper-wrapper-mask");
              swiper.wrapperEl.parentNode.insertBefore(wrapperMask, swiper.wrapperEl);
              wrapperMask.appendChild(swiper.wrapperEl);

              // Force arrows to behave correctly
              changeGradient(swiper, arrows, wrapperMask);
              forceArrowVisibility(swiper, arrows, wrapperMask);

              // Use smaller arrows for tiny cards
              if (swiper.height < 50) {
                arrows.forEach((arrow) => arrow.classList.add("small"));
              }
              fixScroll(swiper, arrows);
            },
            reachBeginning: () => {
              leftArrow.classList.add("swiper-button-disabled");
            },
            reachEnd: () => {
              rightArrow.classList.add("swiper-button-disabled");
            },
            resize: (swiper) => fixScroll(swiper, arrows),
          },
        });
      });
    };

    init();
  };
  document.addEventListener("DOMContentLoaded", BWDTabs);
})();
