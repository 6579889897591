/**
 * Assigns and executes the callback on click, and visually toggles the button active (and others inactive).
 * @param {HTMLElement[]} buttons Array of button HTMLElements
 * @param {Function} callback Callback to do on click. Optional first argument is Event,
 * optional second argument is button element.
 */
export const btnToggle = function (buttons, callback) {
  buttons.forEach((btn) => {
    btn.addEventListener("click", (evt) => {
      callback(evt, btn);
      buttons.forEach((otherBtn) => {
        if (otherBtn === evt.target) {
          otherBtn.classList.add("bwd-weather__button__active");
        } else {
          otherBtn.classList.remove("bwd-weather__button__active");
        }
      });
    });
  });
};

/**
 * Capitalizes the first letter of the string provided.
 * @param {string} str String to capitalize
 * @returns String with the first letter capitalized
 */
export const capitalizeFirstLetter = function (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/**
 * Rounds and formats a TimeZone offset.
 * @param {number} offsetSeconds UTC offset in seconds
 * @param {boolean|undefined} padHours Pad the hour number with zeros
 * @returns {string} UTF offset rounded and formatted as ±HH[:MM]
 */
export const tzOffsetStr = function (offsetSeconds, padHours) {
  const sign = offsetSeconds < 0 ? "-" : "+";
  const offsetMinRound = Math.round(Math.abs(offsetSeconds) / 60);
  const hours = Math.trunc(offsetMinRound / 60);
  const minutes = offsetMinRound % 60;

  const tzString = sign + (padHours ? String(hours).padStart(2, "0") : hours);
  if (minutes === 0) {
    return tzString;
  }
  return tzString + ":" + String(Math.abs(minutes)).padStart(2, "0");
};
