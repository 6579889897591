document.addEventListener("DOMContentLoaded", () => {
  Array.from(document.getElementsByClassName("bwd-hotel-detail__header-back")).forEach((btnBack) => {
    if (history.length < 2) {
      btnBack.classList.add("hidden");
      return;
    }

    btnBack.addEventListener("click", (evt) => {
      evt.preventDefault();
      history.back();
    });
  });
});
