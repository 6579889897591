(() => {
  const BWDWeddingKit = () => {
    const selectors = {
      component: ".bwd-wedding-kit",
      form: ".bwd-wedding-kit__modal__form",
      modal: ".js-bwd-wedding-kit-modal",
      input: ".js-bwd-wedding-kit-input",
      checkbox: ".js-bwd-wedding-kit-checkbox",
      openButton: ".js-bwd-wedding-kit-open",
      closeButton: ".js-bwd-wedding-kit-close",
      sendButton: ".js-bwd-wedding-kit-send",
      responseDiv: ".bwd-wedding-kit__modal__response",
    };

    const init = () => {
      const parentElement = document.querySelectorAll(selectors.component);
      if (parentElement && parentElement.length > 0) {
        parentElement.forEach((element) => {
          const modal = element.querySelector(selectors.modal);
          const allInput = element.querySelectorAll(selectors.input);
          const allCheckbox = element.querySelectorAll(selectors.checkbox);
          const policyCheckbox = element.querySelector("#wedding-kit-policy");
          const openButton = element.querySelector(selectors.openButton);
          const closeButtons = element.querySelectorAll(selectors.closeButton);
          const sendButton = element.querySelector(selectors.sendButton);
          const $form = element.querySelector(selectors.form);
          const $response = element.querySelector(selectors.responseDiv);

          openButton.addEventListener("click", () => {
            modal.classList.add("mod--open");
            $form.style.display = "block";
            $response.classList.add("hidden");
          });

          closeButtons.forEach((close) => {
            close.addEventListener("click", (event) => {
              event.preventDefault();
              modal.classList.remove("mod--open");
              $form.reset();
              resetFormState($form, $response);
            });
          });

          sendButton.addEventListener("click", (event) => {
            event.preventDefault();
            if (validData(allInput, policyCheckbox)) {
              sendData($form, $response);
            }
          });

          allInput.forEach((input) => {
            input.addEventListener("keyup", () => {
              input.parentElement.classList.remove("mod--error");
            });
          });

          allCheckbox.forEach((checkbox) => {
            checkbox.addEventListener("change", () => {
              checkbox.parentElement.classList.remove("mod--error");
            });
          });
        });
      }
    };

    const validData = (allInput, policyCheckbox) => {
      let valid = true;

      const nameInput = allInput[0];
      const name = nameInput.value.trim();
      const nameRegrex = /^[A-Za-zÁÉÍÓÚáéíóúÑñ]+(\s[A-Za-zÁÉÍÓÚáéíóúÑñ]+)*$/;
      if (!nameRegrex.test(name)) {
        valid = false;
        nameInput.parentElement.classList.add("mod--error");
      }

      const emailInput = allInput[1];
      const email = emailInput.value;
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        valid = false;
        emailInput.parentElement.classList.add("mod--error");
      }

      if (policyCheckbox.checked != true) {
        valid = false;
        policyCheckbox.parentElement.classList.add("mod--error");
      }

      return valid;
    };

    const resetFormState = (form, responseDiv) => {
      form.querySelectorAll(".mod--error").forEach((element) => {
        element.classList.remove("mod--error");
      });

      responseDiv.classList.add("hidden");

      responseDiv.querySelectorAll(".bwd-wedding-kit__response-error, .bwd-wedding-kit__response-success").forEach((element) => {
        element.classList.add("hidden");
      });

      responseDiv.querySelectorAll(".bwd-wedding-kit-contact-name, .bwd-wedding-kit-contact-mail").forEach((span) => {
        span.innerHTML = "";
      });
    };
    const showResponse = (form, formData, success, responseDiv) => {
      responseDiv.classList.remove("hidden");
      form.style.display = "none";

      if (!success) {
        responseDiv.querySelector(".bwd-wedding-kit__response-error").classList.remove("hidden");
      } else {
        responseDiv.querySelector(".bwd-wedding-kit__response-success").classList.remove("hidden");
        responseDiv.querySelector(".bwd-wedding-kit-contact-name").innerHTML += `<span> ${formData.get("contact-name")}</span>`;
        responseDiv.querySelector(".bwd-wedding-kit-contact-mail").innerHTML += `<span> ${formData.get("contact-mail")}</span>`;
      }
    };

    const sendData = async ($form, $response) => {
      const url = $form.dataset.url;

      let formData = new FormData($form);
      const hotelTitle = document.querySelector("[data-hotel-title]")?.dataset?.hotelTitle;
      if (hotelTitle) {
        formData.append("hotelTitle", hotelTitle);
      }

      let token;

      const authorMode = document.body.classList.contains("isAuthor");

      if (authorMode) {
        try {
          const response = await fetch("/libs/granite/csrf/token.json");
          const data = await response.json();
          token = data.token;
        } catch (error) {
          console.error("Error al obtener el token CSRF:", error);
          return;
        }

        formData.append(":cq_csrf_token", token);
      }

      const fetchOptions = {
        method: "POST",
        headers: {
          "X-Requested-With": "XMLHttpRequest",
        },
        body: formData,
      };

      try {
        const response = await fetch(url, fetchOptions);
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const jsonResponse = await response.json();

        const success = jsonResponse.success;

        showResponse($form, formData, success, $response);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    init();
  };
  document.addEventListener("DOMContentLoaded", BWDWeddingKit);
})();
