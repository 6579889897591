/* eslint-disable max-len */
import { msToKmh, UNIT_SYSTEM_METRIC } from "./locale";
import { btnToggle, capitalizeFirstLetter, tzOffsetStr } from "./utils";

const weatherAppearance = {
  /**
   * Gets the icon and background effect corresponding to the weather icon name provided by the API.
   *
   * @param {string} iconCode Icon code provided by the API.
   * @param {"day"|"night"} dayOrNight Whether to use day or night icons.
   * @returns {string} String describing the appearance of the weather.
   */
  get: function (iconCode, dayOrNight) {
    iconCode = iconCode.slice(0, 2) + dayOrNight.charAt(0);
    return this[iconCode] || this[iconCode.slice(0, 2)] || this["01d"];
  },
  "01d": "clear-sky-day",
  "01n": "clear-sky-night",
  "02d": "few-clouds-day",
  "02n": "few-clouds-night",
  "03": "scattered-clouds",
  "04": "broken-clouds",
  "09": "shower-rain",
  "10d": "rain-day",
  "10n": "rain-night",
  11: "thunderstorm",
  13: "snow",
  50: "mist",
};

/**
 * Forecast class handles the Forecast card in the Weather component
 */
export class Forecast {
  constructor(elem, localPreferences, lat, lon, callAfterFetch) {
    this.localeStr = localPreferences.localeStr;
    this.lang = localPreferences.lang;
    this.unitsSystem = localPreferences.unitsSystem;
    this.units = localPreferences.units;
    this.lat = lat;
    this.lon = lon;
    this.fetchAndConversions().then((data) => {
      if (!data) {
        return;
      }
      callAfterFetch(data);
      this.data = data;
      this.tzOffsetStr = tzOffsetStr(data.city.timezone, true);
      this.elemListForecasts = this.populateNextDaysForecast(elem.getElementsByClassName("bwd-weather__forecast__nextdays")[0]);

      this.showForecasts(4);
      btnToggle(Array.from(elem.getElementsByClassName("bwd-weather__button")), (_, btn) => {
        this.showForecasts(Number(btn.getAttribute("data-weather-show-forecasts")));
      });

      const dayOrNight = this.isDayOrNight(data.list[0].sunrise, data.list[0].sunset);

      elem.getElementsByClassName("bwd-weather__forecast__temperature")[0].innerText = Math.round(data.list[0].temp[dayOrNight]) + this.units.temp;
      elem.getElementsByClassName("bwd-weather__forecast__description")[0].innerText = data.list[0].weather[0].description;
      elem.querySelector("[data-weather-windspeed]").innerText = Math.round(data.list[0].speed) + " " + this.units.speed;
      elem.querySelector("[data-weather-humidity]").innerText = Math.round(data.list[0].humidity) + "%";

      const appearance = weatherAppearance.get(data.list[0].weather[0].icon, dayOrNight);
      elem.getElementsByClassName("bwd-weather__forecast__card")[0].classList.add(appearance);
      const icon = elem.getElementsByClassName("bwd-weather__forecast__icon")[0];
      icon.src = `/content/dam/barcelo/commons/icons/wedding-weather/${appearance}.svg`;
      icon.alt = capitalizeFirstLetter(data.list[0].weather[0].description);
    });
  }

  /**
   * Returns whether now is considered day or night according to sunrise and sunset.
   * Day is assumed to start 1 hour before sunrise, and end 1 hour after sunset.
   *
   * @param {number} sunrise Sunrise unix timestamp (in seconds).
   * @param {number} sunset Sunset unix timestamp (in seconds).
   * @returns {"day"|"night"} Day or night string.
   */
  isDayOrNight(sunrise, sunset) {
    sunrise -= 60 * 60;
    sunset += 60 * 60;
    const now = new Date().getTime() / 1000;
    return now < sunrise || now > sunset ? "night" : "day";
  }

  /**
   * Fetches the API and returns the response after converting the units.
   * @returns {any|null} Data from the API, or null if there was an error.
   */
  async fetchAndConversions() {
    const resp = await fetch(`/weather/${this.lat}/${this.lon}/${this.lang}/${this.unitsSystem}/climate/`);
    if (!resp.ok) {
      console.error("Error fetching weather API", resp);
      return null;
    }
    const data = await resp.json();

    if (this.unitsSystem === UNIT_SYSTEM_METRIC) {
      data.list[0].speed = msToKmh(data.list[0].speed); // Metric speed is given in m/s
    }
    return data;
  }

  /**
   * Populates the forecast for the next days.
   *
   * It populates only 15 forecasts (to save on performance – 15 is the maximum forecasts shown at the time of the
   * creation of this function), even tho the API provides more days.
   *
   * @param {HTMLElement} divNextDays Div of the forecast for the next days to populate it with data.
   * @returns {HTMLElement[]} The list of elements created, in order of creation.
   */
  populateNextDaysForecast(divNextDays) {
    const forecasts = [];
    this.data.list.slice(1, 16).forEach((forecast) => {
      const div = document.createElement("div");

      const pDay = document.createElement("p");
      pDay.classList.add("day");
      pDay.innerText = capitalizeFirstLetter(
        new Date(forecast.dt * 1000).toLocaleDateString(this.localeStr, {
          day: "numeric",
          month: "long",
          weekday: "long",
          timeZone: this.tzOffsetStr,
        }),
      );
      div.appendChild(pDay);

      const pTemp = document.createElement("p");
      pTemp.classList.add("temperature");
      pTemp.innerText = Math.round(forecast.temp.day) + this.units.temp;
      div.appendChild(pTemp);

      const pDesc = document.createElement("span");
      pDesc.classList.add("description");
      pDesc.innerText = capitalizeFirstLetter(forecast.weather[0].description);
      div.appendChild(pDesc);

      divNextDays.appendChild(div);
      forecasts.push(div);
    });
    return forecasts;
  }

  /**
   * Shows the forecast for the next N days.
   * @param {number} n Number of forecasts to show.
   */
  showForecasts(n) {
    this.elemListForecasts.forEach((forecast, i) => (forecast.style.display = i < n ? "" : "none"));
  }
}
