/**
 * Calculates the minimum and maximum values of the datapoints provided.
 *
 * - If the minimum data point is bigger than 0, then 0 is returned as minimum.
 * - If the maximum data point is smaller than 0, then 0 is returned as maximum.
 * @param {number[]} datapoints Datapoints.
 * @returns {[number, number]} An array containing: (0) minimum, (1) maximum.
 */
const minMax = function (datapoints) {
  let min = 0;
  let max = 0;
  for (let i = 0; i < datapoints.length; i++) {
    const datapoint = datapoints[i];
    if (datapoint < min) {
      min = datapoint;
    }
    if (datapoint > max) {
      max = datapoint;
    }
  }
  return [min, max];
};

/**
 * Calculates nice intervals to divide the barchart vertical axis with.
 * This implementation will approach 4 as the "optimal" number of steps.
 * @param {number} min Minimum value in the data.
 * @param {number} max Maximum value in the data.
 * @returns {number} The number of units per step.
 */
const barChartOptimalStepSize = function (min, max) {
  const steps = [5, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  const diffs = steps.map((step) => Math.abs((max - min) / step - 3));
  let optimalStepIndex = 0;
  for (let i = 0; i < diffs.length; i++) {
    if (diffs[i] <= diffs[optimalStepIndex]) {
      optimalStepIndex = i;
    }
  }
  return steps[optimalStepIndex];
};

/**
 * Calculates the values for the barchart vertical axis.
 *
 * - The smaller value will always equal or less than the minimum value.
 * - The bigger value may be up to 50% less than the max value (this means, the maximum value can overshoot the
 *   biggest value in the left axis by up to 50%).
 * @param {number[]} datapoints Data points to render.
 * @returns {[number[], number]} An array, containing: (0) An array with the value of the steps in the first element,
 * (1) the total Δv (change of values) represented in the barchart axis.
 */
const barChartSteps = function (datapoints) {
  const [min, max] = minMax(datapoints);
  const step = barChartOptimalStepSize(min, max);
  const minStep = Math.ceil(Math.abs(min) / step) * -step;
  const maxStep = Math.ceil((max - step / 2) / step) * step;
  const steps = [];
  for (let n = minStep; n <= maxStep; n += step) {
    steps.push(n);
  }
  return [steps, maxStep - minStep + step / 2];
};

/**
 * BarChart class contains utils to handle BarChart components.
 */
export class BarChart {
  /**
   * @param {HTMLElement} elem BarChart HTML Element.
   */
  constructor(elem) {
    this.elemAxis = elem.querySelector("[data-bwd-weather-barchart-axis]");
    this.elemMargin = elem.getElementsByClassName("bwd-weather__barchart__axis__margin")[0];
    this.elemListBars = Array.from(elem.getElementsByClassName("bwd-weather__barchart__bar"));
  }

  /**
   * Renders the values for the attribute indicated with the unit provided.
   * @param {string} attrName Name of the attribute to render.
   * @param {string} unit Unit of measurement to append.
   * @param {Function|undefined} conversionFunction Function to convert from metric to the unit used.
   */
  render(attrName, unit, conversionFunction) {
    let elemData = this.elemListBars.map((elemBar) => {
      return {
        elem: elemBar,
        value: Number(elemBar.getAttribute(`data-bwd-weather-barchart-${attrName}`)),
      };
    });
    if (conversionFunction) {
      elemData = elemData.map((ed) => {
        return { elem: ed.elem, value: conversionFunction(ed.value) };
      });
    }
    const [steps, deltaValue] = barChartSteps(elemData.map((ed) => ed.value));

    // Render axis
    this.elemAxis.innerHTML = "";
    this.elemMargin.innerHTML = "";
    steps.forEach((step, i) => {
      if (i !== 0) {
        const horizontalLine = document.createElement("div");
        horizontalLine.style.bottom = 100 * ((2 * i) / (steps.length * 2 - 1)) + "%";
        this.elemMargin.appendChild(horizontalLine);
      }

      const div = document.createElement("div");
      div.classList.add("bwd-weather__barchart__index");
      div.style.height = (100 * (i === steps.length - 1 ? 1 : 2)) / (steps.length * 2 - 1) + "%";

      const p = document.createElement("p");
      p.innerText = String(step) + unit;

      div.appendChild(p);
      this.elemAxis.appendChild(div);
    });
    // Adjust bar heights to fit data
    elemData.forEach((ed) => (ed.elem.style.height = String((ed.value + Math.abs(steps[0])) * (100 / deltaValue)) + "%"));
  }
}
