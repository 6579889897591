import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
import * as bwdContactForm from "../../content/bwd-contact-form/bwd-contact-form";
Swiper.use([Navigation, Pagination]);
(() => {
  const BWDPackageDetail = () => {
    const selectors = {
      component: ".bwd-package-detail",
      swiper: ".js-init-swiper",
      modalButton: ".js-open-modal",
      modalBlock: ".js-contact-modal",
    };

    const initBackButton = (component) => {
      Array.from(component.getElementsByClassName("bwd-package-detail__back")).forEach((btnBack) => {
        if (history.length < 2) {
          btnBack.classList.add("hidden");
          return;
        }
        btnBack.addEventListener("click", (evt) => {
          evt.preventDefault();
          history.back();
        });
      });
    };

    const initContactFormButton = (btn) => {
      let alreadyAttachedToModal = false;
      bwdContactForm.addEventListenerOnInserted((contactFormElem) => {
        if (alreadyAttachedToModal) {
          return;
        }
        alreadyAttachedToModal = true;

        const modal = contactFormElem.querySelector(selectors.modalBlock);
        btn.classList.remove("hidden");
        btn.addEventListener("click", () => {
          if (modal) {
            modal.classList.add("mod--open");
            document.documentElement.classList.add("mod--disable-scroll");
          }
        });
      });
    };

    const init = () => {
      const parentElement = document.querySelectorAll(selectors.component);
      if (!parentElement || parentElement.length === 0) {
        return;
      }
      parentElement.forEach((element) => {
        initBackButton(element);
        element?.querySelectorAll(selectors.modalButton)?.forEach((btn) => initContactFormButton(btn));

        const swiperElement = element.querySelector(selectors.swiper);
        if (!swiperElement || swiperElement.getElementsByClassName("swiper-slide").length === 0) {
          return;
        }
        try {
          new Swiper(swiperElement, {
            slidesPerView: 1,
            freeMode: false,
            pagination: {
              el: ".swiper-pagination",
              clickable: true,
            },
            loop: false,
            nested: element.classList.contains("swiper-nested"),
            navigation: {
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            },
          });
        } catch (err) {
          console.error(`Error initializing Swiper in ${selectors.component.substring(1)}`, err);
        }
      });
    };
    init();
  };
  document.addEventListener("DOMContentLoaded", BWDPackageDetail);
})();
