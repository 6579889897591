import Swiper from "swiper";
import { Pagination } from "swiper/modules";

/**
 * Card carousel handler.
 */
export class Cards {
  /**
   * Constructor for cards carousel.
   * @param {HTMLElement} elemContainer Container element of the component.
   * @param {HTMLElement} elemMap Map element of the component.
   */
  constructor(elemContainer, elemMap) {
    /** @type {HTMLElement} */
    this.elemCarousel = elemContainer.getElementsByClassName("bwd-map__card-carousel")[0];

    /** @type {HTMLElement} */
    this.elemCountryName = this.elemCarousel.getElementsByClassName("bwd-map__country-cards__hotel")[0];

    /** @type {HTMLElement} */
    this.elemMap = elemMap;

    /** @type {HTMLElement} */
    this.elemNumberOfCards = this.elemCarousel.getElementsByClassName("bwd-map__country-cards__number")[0];

    /** @type {{next: Element, prev: Element}} */
    this.elemSwiperNav = {
      next: this.elemCarousel.getElementsByClassName("card-button-next")[0],
      prev: this.elemCarousel.getElementsByClassName("card-button-prev")[0],
    };

    /** @type {HTMLElement} */
    this.elemSwiperWrapper = this.elemCarousel.getElementsByClassName("swiper-wrapper")[0];

    /** @type {Swiper} */
    this.swiper = new Swiper(this.elemCarousel.getElementsByClassName("swiper-container")[0], {
      observeParents: true,
      observer: true,
      on: {
        progress: (swiper) => {
          this.elemSwiperNav.prev.classList.toggle("swiper-button-disabled", swiper.isBeginning);
          this.elemSwiperNav.next.classList.toggle("swiper-button-disabled", swiper.isEnd);
        },
      },
      spaceBetween: 24,
    });
    this.elemSwiperNav.next.addEventListener("click", () => this.swiper.slideNext());
    this.elemSwiperNav.prev.addEventListener("click", () => this.swiper.slidePrev());
  }

  /**
   * Loads the cards of the country provided in the card carousel.
   * @param {Map<string, HotelMarker>} markers Hotel markers to load.
   * @param {string} countryName Name of the country.
   * @returns {Promise<void>} Promise that resolves after load is complete.
   */
  async loadCountryCards(markers, countryName) {
    this.elemCountryName.innerText = countryName;

    // Clear previous content and put cards placeholders while loading
    this.elemSwiperWrapper.innerHTML = "";
    for (let i = 0; i < 2; i++) {
      const div = document.createElement("div");
      div.classList.add("loading", "swiper-slide");
      this.elemSwiperWrapper.appendChild(div);
    }

    // Load cards
    let cards = await Promise.all(
      Array.from(markers.values()).map(async (hotel) => {
        let data;
        try {
          const resp = await fetch(hotel.path);
          if (!resp.ok) {
            console.error("bwd-map: cards: server responded with error response:", resp);
            return null;
          }
          data = await resp.text();
        } catch (err) {
          console.error("bwd-map: cards: error loading data:", err);
          return null;
        }
        hotel.html = data;

        const div = document.createElement("div");
        div.classList.add("swiper-slide");
        div.innerHTML = data;
        return div;
      }),
    );
    cards = cards.filter((card) => card);
    this.elemNumberOfCards.innerText = String(cards.length);

    // Show cards
    this.elemSwiperWrapper.innerHTML = "";
    cards.forEach((div) => {
      this.elemSwiperWrapper.appendChild(div);
      if (div.getElementsByClassName("swiper-slide").length > 1) {
        new Swiper(div.getElementsByClassName("swiper")[0], {
          loop: true,
          modules: [Pagination],
          nested: true,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        });
      }
    });
    this.swiper.update();
  }

  /**
   * Shows or hides the card carousel.
   * @param {boolean} show Whether to show or hide the carousel.
   */
  showCarousel(show) {
    this.elemCarousel.classList.toggle("hidden", !show);
    this.elemMap.classList.toggle("full-width", !show);
    this.elemMap.classList.toggle("open", show);
    if (show) {
      this.swiper.slideTo(0);
    }
  }
}
