(() => {
  const BWDRatingsBanner = () => {
    const selectors = {
      component: ".bwd-ratings-banner",
      wrapper: ".bwd-ratings-banner__wrapper",
      stars: ".bwd-ratings-banner__star-block",
    };

    const calculateRatingValue = (component) => {
      const stars = Array.from(component.querySelectorAll(selectors.stars));
      const ratingValue = component.querySelector(selectors.wrapper).dataset.rating;
      const percentageToDraw = (ratingValue % 1).toFixed(1) * 100;
      const starWidth = 34.3;
      let partialStarIndex;

      stars.forEach((star, i) => {
        if (i + 1 <= ratingValue) {
          star.classList.remove("hidden");
        } else {
          partialStarIndex = i;
        }
      });

      if (percentageToDraw > 0) {
        stars[partialStarIndex].classList.remove("hidden");
        stars[partialStarIndex].style.width = (percentageToDraw / 100) * starWidth + "px";
      }
    };

    const init = () => {
      const ratingComponents = Array.from(document.querySelectorAll(selectors.component));
      ratingComponents.forEach((rating) => {
        calculateRatingValue(rating);
      });
    };

    init();
  };
  document.addEventListener("DOMContentLoaded", BWDRatingsBanner);
})();
