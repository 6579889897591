(() => {
  const BWDHeader = () => {
    const selectors = {
      header: "header",
    };

    const classes = {
      stickyHeader: "mod--header-sticky",
    };

    const init = () => {
      const headerComponents = Array.from(document.querySelectorAll(selectors.header));
      headerComponents.forEach((header) => {
        if (document.body.classList.contains(classes.stickyHeader)) {
          header.classList.add(classes.stickyHeader);
        }
      });
    };
    init();
  };
  document.addEventListener("DOMContentLoaded", BWDHeader);
})();
