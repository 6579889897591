export const UNIT_SYSTEM_IMPERIAL = "imperial";
export const UNIT_SYSTEM_METRIC = "metric";

/**
 * Returns the local preference from the locale of the page.
 * @returns {{lang: string, localeStr: string, units: {speed: string, temp: string}, unitsSystem: string}}
 */
export const localPreferences = function () {
  const localeStr = document.documentElement.lang;
  const lang = localeStr.slice(0, 2);
  const unitsSystem = document.documentElement.dataset.market.toLowerCase() === "us" ? UNIT_SYSTEM_IMPERIAL : UNIT_SYSTEM_METRIC;
  const units = { precipitation: "mm" };
  switch (unitsSystem) {
    case "imperial":
      units.speed = "Mph";
      units.temp = "°F";
      break;
    case "metric":
      units.speed = "Km/h";
      units.temp = "°C";
      break;
    default:
      units.speed = "M/s";
      units.temp = " K";
      break;
  }
  return { lang, localeStr, units, unitsSystem };
};

/**
 * Translate the dynamic components of the website.
 * @param {HTMLElement} root Root element of the component to translate
 * @param {string} localeStr Current page locale
 */
export const translate = function (root, localeStr) {
  Array.from(root.querySelectorAll("[data-bwd-weather-i18n-month]")).forEach((elem) => {
    elem.innerText = new Date(0, Number(elem.getAttribute("data-bwd-weather-i18n-month")) - 1).toLocaleDateString(localeStr, { month: "short" }).toLocaleUpperCase();
  });
};

/**
 * Converts speed from m/s to km/h
 * @param {number} s Speed in meters per second
 * @returns {number} Speed in kilometers per hour
 */
export const msToKmh = function (s) {
  return s * 3.6;
};

/**
 * Converts temperature from °C to °F
 * @param {number} t Temperature in Celsius
 * @returns {number} Temperature in Fahrenheit
 */
export const cToF = function (t) {
  return t * 1.8 + 32;
};
