import { BarChart } from "./barchart";
import { Forecast } from "./forecast";
import { cToF, localPreferences, translate, UNIT_SYSTEM_IMPERIAL } from "./locale";
import { btnToggle, tzOffsetStr } from "./utils";

const prefs = localPreferences();
const tempConvFunc = prefs.unitsSystem === UNIT_SYSTEM_IMPERIAL ? cToF : undefined;
Array.from(document.getElementsByClassName("bwd-weather")).forEach((weatherCmpInstance) => {
  const grid = weatherCmpInstance.getElementsByClassName("bwd-weather__grid")[0];
  const lat = Number(grid.getAttribute("data-bwd-weather-latitude"));
  const lon = Number(grid.getAttribute("data-bwd-weather-longitude"));

  const cardHistoric = weatherCmpInstance.getElementsByClassName("bwd-weather__historic")[0];
  const barchart = new BarChart(cardHistoric.getElementsByClassName("bwd-weather__barchart")[0]);
  barchart.render("temperature", prefs.units.temp, tempConvFunc);
  btnToggle(Array.from(cardHistoric.getElementsByClassName("bwd-weather__button")), (_, btn) => {
    const attrName = btn.getAttribute("data-bwd-weather-barchart-attribute-name");
    let unit = prefs.units.temp;
    let convFunc = tempConvFunc;
    if (attrName === "precipitations") {
      unit = prefs.units.precipitation;
      convFunc = undefined;
    }
    barchart.render(attrName, unit, convFunc);
  });

  const cardForecast = weatherCmpInstance.getElementsByClassName("bwd-weather__forecast")[0];
  new Forecast(cardForecast, prefs, lat, lon, (data) => {
    let tzStr = tzOffsetStr(data.city.timezone);
    if (tzStr === "+0") {
      tzStr = "";
    }
    weatherCmpInstance.querySelector("[data-weather-timezone]").innerText = "GMT " + tzStr;
  });
  translate(weatherCmpInstance, prefs.localeStr);
});
