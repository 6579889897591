const all = { elementType: "geometry", stylers: [{ color: "#F3EDE8" }] };
const countries = {
  featureType: "administrative.country",
  elementType: "geometry.stroke",
  stylers: [{ color: "#808080" }, { weight: 0.2 }, { opacity: 0.3 }],
};
const labels = {
  elementType: "labels",
  stylers: [{ visibility: "off" }],
};
const markerLabels = {
  color: "#000000",
  fontSize: "14px",
  fontWeight: "bold",
};
const water = { featureType: "water", elementType: "geometry", stylers: [{ color: "#D2CECB" }] };

export const gMapStyles = [all, countries, labels, water];
export const labelWithStyles = (text) => {
  return { text, ...markerLabels };
};
