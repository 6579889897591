Array.from(document.getElementsByClassName("bwd-article-share__btn")).forEach((btn) => {
  btn.addEventListener("click", () => {
    const elemArticleHeader = document.getElementsByClassName("bwd-article-header")[0];
    const shareObj = {
      title: elemArticleHeader?.getElementsByClassName("cmp-title__subtitle")[0]?.textContent?.replace(/\s+/g, " ")?.trim() || document.title,
      url: location.href,
    };

    const description = elemArticleHeader?.getElementsByClassName("bwd-article-header__description")[0]?.textContent?.replace(/\s+/g, " ")?.trim() || document.querySelector("meta[name=description]")?.getAttribute("content");
    if (description) {
      shareObj.text = description;
    }

    navigator.share(shareObj);
  });
});
